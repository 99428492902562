import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Flex,
  Button,
  Select,
  useDisclosure,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";
import {
  getAddress,
  postBooking,
  postPayment,
  getBusinessSetting,
  getWallet,
} from "../../api-services";
import ServiceDetails from "../../components/ServiceDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ManageAddress from "../../model/ManageAddress";
import PaymentComponent from "../../components/PaymentGatway";
import ProviderComponent from "../../components/Provider";
import { useLocation } from "react-router-dom";
import moment from "moment";

const convertDateTime = (dateTimeString) => {
  // const bookingDate = date.toISOString().split("T")[0]; // Extracts date in YYYY-MM-DD format
  // const hours = String(date.getHours()).padStart(2, "0");
  // const minutes = String(date.getMinutes()).padStart(2, "0");
  // const bookingTime = `${hours}:${minutes}`;

  const dateTime = moment(dateTimeString);

  const bookingDate = dateTime.format("YYYY-MM-DD");
  const bookingTime = dateTime.format("HH:mm");

  return {
    booking_date: bookingDate,
    booking_time: bookingTime,
  };
};

const providerTypes = [
  { id: "freelancer", name: "Freelancer" },
  { id: "company", name: "Company" },
];

const BookingPage = () => {
  const { redDark } = useSelector((state) => state.theme);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // const [searchParams] = useSearchParams();
  const paymentMethod = searchParams.get("paymentMode");
  const chooseProvider = searchParams.get("chooseProvider");
  const selectId = searchParams.get("id");

  const {
    isOpen: isAddressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useDisclosure();

  const [calendarDate, setCalendarDate] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [confirming, setConfirming] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [businessSetting, setBusinessSetting] = useState([]);
  const [walletAmt, setWalletAmt] = useState(0);
  const [addressId, setAddressId] = useState(null);
  const [bookindData, setBookingData] = useState(null);
  const [bookindConfirmedData, setBookingConfirmedData] = useState(null);

  // const [newProviderId, setNewProviderId] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerOpen, setProviderOpen] = useState(false); // State to manage the open/close status
  const [selectedProviderData, setSelectedProviderData] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isWalletChecked, setIsWalletChecked] = useState(false);
  const [isAutoMatch, setIsAutoMatch] = useState(true);

  // Validation errors
  const [addressError, setAddressError] = useState("");
  const [dateError, setDateError] = useState("");

  // Get today's date and add 1 day to prevent selection of current and past dates
  const today = new Date();
  const minSelectableDate = new Date(today.setDate(today.getDate() + 1));

  const handleChangeAddress = (e) => {
    setAddressId(e.target.value);
    setAddressError(""); // Clear error when valid input is provided
  };

  useEffect(() => {
    let provider;
    chooseProvider == "freelancer_prices"
      ? (provider = "freelancer")
      : (provider = "company");
    setSelectedProvider(provider);
  }, [chooseProvider]);

  const handleProviderChange = (event) => {
    console.log(typeof event);

    if (typeof event !== "string") {
      setSelectedProvider(event?.target?.value);
      setSelectedProviderData(null);
      if (event?.target?.value !== "") {
        if (selectedProvider === "company" && !calendarDate) {
          setErrorState("Please choose a date first.");
          return;
        }
        setProviderOpen(true);
      }
    }
    if (typeof event == "string" && event !== "") {
      setSelectedProvider(event);
      if (selectedProvider === "company" && !calendarDate) {
        setErrorState("Please choose a date first.");
        return;
      }
      setProviderOpen(true);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (
      paymentMethod === "online" &&
      bookindData?.qty !== selectedProviderData?.length &&
      selectedProvider === "freelancer" &&
      !isAutoMatch
    ) {
      setErrorState(`please select providers.`);
      isValid = false;
    }

    if (
      paymentMethod === "online" &&
      bookindData?.qty !== selectedProviderData?.length &&
      selectedProvider === "company"
    ) {
      setErrorState(`please select premium and manpower.`);
      isValid = false;
    }

    if (
      paymentMethod === "online" &&
      selectedProviderData == null &&
      selectedProvider === "freelancer" &&
      !isAutoMatch
    ) {
      setErrorState(`Choose provider manually or auto match.`);
      isValid = false;
    }

    // if (
    //   paymentMethod === "online" &&
    //   bookindData?.qty !== selectedProviderData?.length &&
    //   selectedProvider === "freelancer" && isAutoMatch
    // ) {
    //   setErrorState(`please choose providers manually or auto match.`);
    //   isValid = false;
    // }

    // if (
    //   paymentMethod === "online" &&
    //   bookindData?.qty !== selectedProviderData[2]
    // ) {
    //   setErrorState(`please select Premium.`);
    //   isValid = false;
    // }

    if (!addressId) {
      setAddressError("Address is required.");
      isValid = false;
    }

    if (calendarDate == null) {
      setDateError("Date is required.");
      isValid = false;
    }

    return isValid;
  };
  const bookNow = () => {
    if (!validateForm()) return;
    setAddressError("");
    setErrorState("");
    handleConfirm();
    setDateError("");
    // if (paymentMethod !== "online") {
    //   handleConfirm();
    // } else {
    //   setIsPaymentOpen(true);
    // }
    // handleConfirm();
  };
  const handleConfirm = async () => {
    // if (!validateForm()) return;
    // setError("");
    // setMessage("");

    let provider;
    chooseProvider == "freelancer_prices"
      ? (provider = "freelancer")
      : (provider = "company");

    setConfirming(true);

    const { booking_date, booking_time } = convertDateTime(calendarDate);
    const payload = {
      services: [bookindData],
      business_id:
        provider === "freelancer"
          ? selectedProviderData || []
          : selectedProviderData?.[0] || [],
      count_employee:
        provider === "freelancer" ? [] : selectedProviderData?.[1] || [],
      booking_date: booking_date,
      booking_time: booking_time,
      payment_mode:
        isWalletChecked && walletAmt.wallet_amount >= grandTotal
          ? "wallet"
          : paymentMethod,
      address_id: Number(addressId),
      is_wallet: isWalletChecked,
      booking_type: provider,
      total_manpower: bookindData?.qty,
    };
    try {
      const res = await postBooking(payload);
      if (res?.success === false) {
        toast({
          title: "Error",
          description: res?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        // setError(res?.message);
      } else {
        if (paymentMethod === "online") {
          setBookingConfirmedData(res?.response);
          setIsPaymentOpen(true);
          // paymentPost();
        } else {
          // setMessage(res?.message);
          toast({
            title: "Success",
            description: res?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setTimeout(() => {
            setConfirming(false);
            navigate("/customer-booking-history");
          }, 1000);
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setConfirming(false);
    }
  };

  const handleAutoMatch = async () => {
    if (!isAutoMatch) setSelectedProviderData(null);
    setIsAutoMatch((prev) => !prev);
  };
  const paymentPost = async (razorpayPaymentId) => {
    try {
      const payload = {
        payment_id: razorpayPaymentId,
        payment_status: "success",
      };
      const res = await postPayment(payload, bookindConfirmedData?.booking_id); // Fetch about content
      console.log("get message", res);
      setIsPaymentOpen(false);
      if (res?.success === false) {
        toast({
          title: "Error",
          description: res?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        // setError(res?.message);
      } else {
        toast({
          title: "Success",
          description: res?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          setConfirming(false);
          navigate("/customer-booking-history");
        }, 1000);
      }
    } catch (error) {
      setError("Failed to load content"); // Set error if the request fails
    } finally {
      setLoading(false); // Disable loading once data is fetched
    }
  };
  useEffect(() => {
    fetchData();
    fetchBusinessSetting();
    fetchWalletData();
  }, []);

  const fetchData = async () => {
    setError("");
    try {
      const res = await getAddress();
      setAddresses(res?.response || []);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchWalletData = async () => {
    setError("");
    try {
      const res = await getWallet();
      setWalletAmt(res || {});
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBusinessSetting = async () => {
    setError("");
    try {
      const res = await getBusinessSetting();
      setBusinessSetting(res?.response || []);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTotalsChange = (total, taxA, grandTotal, tax, allData) => {
    setTax(tax);
    setBookingData(allData);
    setTotalAmount(total);
    setTaxAmount(taxA);
    setGrandTotal(grandTotal);
  };
  const onAddressCloseHandlers = () => {
    onAddressClose();
    fetchData();
  };
  const selectProvider = (data) => {
    setSelectedProviderData(data);
    setProviderOpen(false);
    if (data?.length > 0) {
      setErrorState(null);
      setIsAutoMatch(false);
    }
    if (data[0]?.length > 0) {
      setErrorState(null);
    }
    // handleOpenBookingModal(true);
  };
  const closeProvider = () => {
    setProviderOpen(false);
    // setSelectedProvider("");
  };
  const handlePaymentSuccess = (response) => {
    console.log("Payment Success:", response);
    // Handle success logic
    paymentPost(response);
    setIsPaymentOpen(false);
  };

  const handlePaymentFailure = (response) => {
    console.log("Payment Failure:", response);
    // Handle failure logic
    setIsPaymentOpen(false);
  };

  const handlePaymentCancel = () => {
    console.log("Payment Cancelled");
    // Handle cancellation logic
    setIsPaymentOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setIsWalletChecked(e.target.checked);
    console.log("Checkbox is checked:", e.target.checked); // You can use this to log or perform any action based on the checkbox state
  };

  return (
    <>
      <Box
        maxW="container.xxl"
        mx="auto"
        px={{ base: "10px", md: "30px" }}
        pt="60px"
        pb="30px"
        mt="60px"
      >
        <Flex direction={{ base: "column", lg: "row" }}>
          <Box
            maxW={{ base: "100%", lg: "1200px" }}
            mx="auto"
            w="100%"
            p={4}
            mt={{ base: 4, lg: 0 }}
            bg="gray.100"
            borderRadius="md"
            boxShadow="lg"
            position="relative"
          >
            <Box mt={6} p={4} bg="white" borderRadius="md" boxShadow="md">
              <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Heading as="h5" size="md">
                  Service Details
                </Heading>
                {paymentMethod === "cod" ? null : (
                  <Checkbox
                    size="md"
                    isChecked={isWalletChecked}
                    onChange={handleCheckboxChange}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Use Wallet : ₹ {walletAmt.wallet_amount}
                    </span>
                  </Checkbox>
                )}
              </Flex>
              <ServiceDetails
                onTotalsChange={handleTotalsChange}
                chooseProvider={chooseProvider}
                businessTax={businessSetting?.tax}
                plateformTax={businessSetting?.admin_fees}
              />

              <Text fontWeight="bold" mt={4} mb="10px">
                Total Amount: ₹{totalAmount}
              </Text>
              <Text fontWeight="bold" color="red" mb="10px">
                Tax: {businessSetting?.tax ?? 0}%
              </Text>
              <Text fontWeight="bold" mb="10px">
                Plateform Fee: {businessSetting?.admin_fees || 0}%
              </Text>
              {/* <Text fontWeight="bold" color="red" mb="10px">
                Tax ({businessSetting?.tax}%): ₹{taxAmount}
              </Text> */}
              <Text fontWeight="bold" color={redDark} mb="10px">
                Grand Total: ₹{grandTotal || 0}
              </Text>
            </Box>
            <Box mt={6}>
              <DatePicker
                selected={calendarDate}
                onChange={(date) => {
                  setCalendarDate(date);
                  setDateError("");
                  setErrorState("");
                }}
                showTimeSelect
                dateFormat="Pp" // Example: "MM/dd/yyyy h:mm aa"
                className="calendarPop"
                minDate={minSelectableDate} // Prevent selection of current or previous dates
                placeholderText="Pick the Date"
              />
              {dateError && (
                <Text color="red" mt={2}>
                  {dateError}
                </Text>
              )}
            </Box>
            <Box mt={6}>
              <Select
                onChange={handleChangeAddress}
                value={addressId}
                placeholder="Select address"
                borderRadius="5px"
              >
                {addresses.map((address, index) => {
                  return (
                    <option value={address?.id} key={index}>
                      {address.address_line_one}{" "}
                      {`${address.city}, ${address.state} - ${address.pincode}`}
                    </option>
                  );
                })}
              </Select>
              {addressError && (
                <Text color="red" mt={2}>
                  {addressError}
                </Text>
              )}
            </Box>
            <br />
            {paymentMethod === "online" && (
              <Box
                display="flex"
                // alignItems="flex-end"
                gap="8px"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="flex-end"
                  gap="8px"
                  borderBottom="1px solid #0101011f"
                >
                  <Text
                    colorScheme="#1E88E51f"
                    variant="outline"
                    fontSize="1.1rem"
                    fontWeight="600"
                  >
                    {selectedProvider === "company" ? "Premium" : "Standard"}{" "}
                    {/* {"->"} */}
                  </Text>

                  {selectedProvider != "" && (
                    <Button
                      variant="outline"
                      colorScheme="#1E88E51f"
                      // isDisabled={isAutoMatch}
                      style={{
                        margin: "5px",
                        // textDecoration: "underline",
                        cursor: "pointer",
                        color: "#1E88E5",
                        fontSize: "14px",
                      }}
                      onClick={() => handleProviderChange(selectedProvider)}
                    >
                      Pick Your Pro.{" "}
                      {/* {selectedProvider === "company" ? "Premium" : "Standard"} */}
                    </Button>
                  )}
                  {errorState && (
                    <Text color="red" mt={2}>
                      {errorState}
                    </Text>
                  )}
                </Box>
                {selectedProvider !== "company" && (
                  <Box>
                    {selectedProvider !== "" && (
                      <Checkbox
                        size="md"
                        isChecked={isAutoMatch}
                        onChange={handleAutoMatch}
                      >
                        Auto Match
                      </Checkbox>
                    )}
                  </Box>
                )}
              </Box>
            )}
            <br />
            <Button
              variant="outline"
              onClick={onAddressOpen}
              border="1px solid"
              fontSize="14px"
              // bg="#1E88E5"
              color="#010101"
              _hover={{
                bg: "#1E88E51f",
                border: "1px solid #1E88E5",
                color: "#1E88E5",
              }}
            >
              Add new Address
            </Button>
            <br />
            <br />
            <br />
            <br />
            <Text fontWeight="600">
              PLEASE NOTE: Once a booking is confirmed, it cannot be canceled.
            </Text>
            <Button
              mt={6}
              colorScheme="teal"
              onClick={bookNow}
              isLoading={confirming}
              loadingText="Confirming"
            >
              Book Now
            </Button>
          </Box>
        </Flex>
      </Box>

      <Text color="red" mt={2}>
        {error}
      </Text>
      <Text color="green" mt={2}>
        {message}
      </Text>

      <ManageAddress
        isOpen={isAddressOpen}
        onClose={() => onAddressCloseHandlers()}
      />

      {/* <ProviderSelectionModal isOpen={true} /> */}

      {providerOpen && (
        <ProviderComponent
          isOpen={providerOpen}
          onClose={closeProvider}
          type={selectedProvider}
          selectProviders={selectProvider}
          totalProvider={bookindData?.qty}
          viewSelected={selectedProviderData}
          favSelected={[parseInt(selectId)]}
          selectedDate={calendarDate}
        />
      )}

      {isPaymentOpen && (
        <PaymentComponent
          onSuccess={handlePaymentSuccess}
          onFailure={handlePaymentFailure}
          onCancel={handlePaymentCancel}
          data={bookindConfirmedData}
        />
      )}
    </>
  );
};

export default BookingPage;
