import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Text,
} from "@chakra-ui/react";

const PaymentSelect = ({
  isOpen,
  onClose,
  paymentMethod,
  selectedProviderData,
}) => {
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
    setSelectedProvider(""); // Reset provider selection when changing payment type
  };

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const paymentTypes =
    selectedProviderData !== "company_prices"
      ? [
          { id: "online", name: "Online" },
          // { id: "cod", name: "Cash" },
        ]
      : [{ id: "online", name: "Online" }];

  const providerTypes = [
    { id: "freelancer", name: "Freelancer" },
    { id: "company", name: "Company" },
  ];

  return (
    <div>
      <Modal isOpen={isOpen} onClose={() => onClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Payment Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Payment Type Dropdown */}
            <Select
              placeholder="--Choose a payment type--"
              value={selectedPayment}
              onChange={handlePaymentChange}
            >
              {paymentTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>

            {/* Show Provider Dropdown if "Online" is selected */}
            {/* {selectedPayment === "online" && (
              <div style={{ marginTop: "20px" }}>
                <Select
                  placeholder="--Choose a provider--"
                  value={selectedProvider}
                  onChange={handleProviderChange}
                >
                  {providerTypes.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
                </Select>
              </div>
            )} */}

            {/* Display selected payment and provider */}
            {selectedPayment && (
              <Text mt={4}>
                You selected: {selectedPayment}{" "}
                {selectedProvider && `with ${selectedProvider}`}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() =>
                paymentMethod({
                  payment: selectedPayment,
                  provider: selectedProviderData,
                })
              }
              // isDisabled={selectedPayment === "online" && !selectedProvider} // Disable submit if provider is not selected
            >
              Submit
            </Button>
            <Button colorScheme="blue" mr={3} onClick={() => onClose()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PaymentSelect;
